<template>
    <mapbox-map
        mapId="base-map"
        class="base-map"
        :options="mapOptions"
        :padding="padding"
    />

</template>

<script setup lang="ts">
import { FillableOrder, MapPadding } from '@passengerkit/shared/types/types';
import pkg from 'mapbox-gl'
import polyline from "@mapbox/polyline"

const { Marker, Map } = pkg

const mapCenter = ref<[number, number]>([0, 0])
const mapZoom = ref(15)

const { newOrder, padding } = defineProps<{
    newOrder?: FillableOrder,
    padding?: MapPadding
}>()

const paddingToWatch = computed(() => {
    return padding
})

watch(paddingToWatch, (val) => {
    if(val == undefined){
        return
    }
    map.value?.easeTo({
        padding: val,
        duration: 1000 // In ms. This matches the CSS transition duration property.
    });
})

const mapOptions = ref({
    style: "mapbox://styles/leonceaklin/clgv26br7007601qy3w5g81gz",
    center: mapCenter.value,
    zoom: mapZoom.value,
    padding: padding
})

const map = ref<Map | null>(null)

useMapbox("base-map", (mapboxMap) => {
    map.value = mapboxMap
    
    if (padding != undefined) {
        setTimeout(() => {
            map.value?.easeTo({
                padding: padding,
                duration: 0 // In ms. This matches the CSS transition duration property.
            });
            drawFeatures();
        }, 1);
    }

    drawFeatures()
})

const firstTimeDrawingFeatures = ref(true)


const newOrderPickupMarker = ref<Marker | null>(null)
const newOrderDestinationMarker = ref<Marker | null>(null)

watch(newOrder, () => {
    drawFeatures()
}, {deep: true})

function drawFeatures(){
    if(!map.value){
        return
    }
    displayNewOrderOnMap()
    firstTimeDrawingFeatures.value = false
}

function displayNewOrderOnMap(){
    newOrderPickupMarker.value?.remove()
    newOrderDestinationMarker.value?.remove()

    if(!map.value){
        return
    }

    if (
        typeof newOrder?.pickupLocation?.name == 'undefined'
        && typeof newOrder?.destinationLocation?.name == 'undefined'
    ) {
        return
    }

    var pickupMarkerPos: [number, number] | null = null


    if(newOrder?.pickupLocation?.latitude){
        pickupMarkerPos = [newOrder!.pickupLocation!.longitude, newOrder!.pickupLocation!.latitude]
        newOrderPickupMarker.value = new Marker()
            .setLngLat(pickupMarkerPos)
            .addTo(map.value);
    }

    var destinationMarkerPos: [number, number] | null = null

    if(newOrder?.destinationLocation?.latitude){
        destinationMarkerPos = [newOrder!.destinationLocation!.longitude, newOrder!.destinationLocation!.latitude]
        newOrderDestinationMarker.value = new Marker()
            .setLngLat(destinationMarkerPos)
            .addTo(map.value);
    }

    if(newOrder?.route){
        const geojson = polyline.toGeoJSON(newOrder.route.geometry);

        console.log(geojson)

        if(map.value.getSource('newOrderRoute')){
            map.value.getSource('newOrderRoute').setData(geojson)
        }
        else{
            map.value.addSource('newOrderRoute', {
                'type': 'geojson',
                'data': geojson
            })
        }

        if(!map.value.getLayer('newOrderRouteLayer')){
            map.value.addLayer({
                id: 'newOrderRouteLayer',
                type: 'line',
                source: 'newOrderRoute',
                paint: {
                    'line-color': '#0099ff',
                    'line-width': 8
                }
            });
        }
    }

    if(pickupMarkerPos != null && destinationMarkerPos != null){
        mapZoom.value = 6
        mapCenter.value = [
            (pickupMarkerPos[0] + destinationMarkerPos[0]) / 2,
            (pickupMarkerPos[1] + destinationMarkerPos[1]) / 2
        ]

        map.value?.fitBounds([
            pickupMarkerPos,
            destinationMarkerPos
        ], {
            padding: 100,
            duration: firstTimeDrawingFeatures.value? 0 : 2000
        })
    }
    else if(pickupMarkerPos != null){
        mapZoom.value = 15
        mapCenter.value = pickupMarkerPos
        map.value.flyTo({
            center: pickupMarkerPos,
            zoom: mapZoom.value,
        })

        if(firstTimeDrawingFeatures.value){
            map.value.setCenter(pickupMarkerPos)
            map.value.setZoom(mapZoom.value)
        }
    }
    else if(destinationMarkerPos != null){
        mapZoom.value = 15
        mapCenter.value = destinationMarkerPos
        map.value.flyTo({
            center: destinationMarkerPos,
            zoom: mapZoom.value,
        })

        if (firstTimeDrawingFeatures.value) {
            map.value.setCenter(pickupMarkerPos)
            map.value.setZoom(mapZoom.value)
        }
    }
}

</script>

<style lang="scss">
.base-map {
    height: 100%;
    width: 100%;
}
</style>