<template>

    <dialog>
        <nuxt-link class="locale-link" v-for="locale in selectLocales" :to="switchLocalePath(locale)">{{ locale }}</nuxt-link>
    </dialog>
</template>

<style lang="scss" scoped>
.locale-link {
    text-transform: uppercase;
}
</style>

<script setup>
import { useI18n } from "vue-i18n"
const i18n = useI18n()

const switchLocalePath = useSwitchLocalePath()

const selectLocales = computed(() => {
    return i18n.availableLocales.filter((code) => code != i18n.locale.value)
})
</script>