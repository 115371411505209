<template>
    <div class="partial-map-sidebar">
        <div :class="{'map-container': true, 'mandatory': mapMandatory}" v-if="showMap">
            <slot name="map"/>
        </div>
        <div :ref="mobileScroller" @scroll="onMobileScroll" :class="{'sidebar-container': true, 'no-scroll-snap': noScrollSnap}">
            <div class="mobile-scroll-snapping-spacer large" v-if="mapMandatory"></div>
            <div class="mobile-scroll-snapping-spacer small" v-if="mapMandatory"></div>
            <div :class="{'sidebar-card': true, 'with-map': mapMandatory}" @scroll="onScroll">
                <div class="sidebar-header">
                    <slot name="sidebar-header"/>
                </div>

                <div class="sidebar-content">
                    <slot name="sidebar"/>
                    <div v-if="slots['sidebar-footer']" class="sidebar-footer-spacer"></div>
                </div>

                <div class="sidebar-footer" v-if="slots['sidebar-footer']">
                    <slot name="sidebar-footer"/>
                </div>
            </div>
        </div>
        <div class="footer-container" v-if="slots['footer']">
            <div class="footer">
                <slot name="footer"/>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const slots = useSlots()

function onMobileScroll(e: any){
    if(!mapMandatory){
        return;
    }
    e.target.scrollTop > window.innerHeight * .6 ? noScrollSnap.value = true : noScrollSnap.value = false
}

function onScroll(e: any){
    if(!slots.footer){
        return
    }
}

const { mapMandatory } = defineProps<{mapMandatory?: boolean}>()
const noScrollSnap = ref(true)
const mobileScroller = ref()

const showMap = ref(true)

onMounted(() => {
    checkShowMap()
    window.addEventListener('resize', checkShowMap)
})

function checkShowMap(){
    if(window.innerWidth > 800){
        showMap.value = true
    }
    else{
        showMap.value = mapMandatory ? true : false
    }
}

</script>

<style scoped lang="scss">
    .partial-map-sidebar {
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100vw;
        overflow: hidden;
        height: 100vh;

        @media (max-width: 800px){
            height: 100vh;
            display: block;
            overflow: visible;
        }
    }

    .map-container{
        position: absolute;
        width: 100%;
        height: 100%;

        > div{
            top: 0px;
            position: absolute;
        }

        @media (max-width: 800px){
            display: none;

            &.mandatory{
                display: block;
            }
        }
    }

    .sidebar-container {
        position: absolute;
        height: 100%;
        width: 600px;
        overflow: auto;
        display: flex;
        padding: 40px;
        left: 0;
        scroll-snap-align: end;

        @media (max-width: 800px) {
            display: block;
            position: relative;
            width: 100%;
            padding: 0px;
            overflow: visible;
            scroll-behavior: smooth;
            scroll-snap-type: y mandatory;
        }

        &.no-scroll-snap{
            scroll-snap-type: none;
        }
    }

    .mobile-scroll-snapping-spacer{
        display: none;
        width: 100%;
        pointer-events: none;

        scroll-snap-align: start;

        @media (max-width: 800px) {
            display: block;
        }

        &.small{
            height: 20vh;
        }

        &.large {
            height: 50vh;
        }

        &.snap-bottom{
            scroll-snap-align: end;
        }
    }

    .sidebar-card{
        background: rgba(var(--background-color), 0.8);
        backdrop-filter: blur(40px) saturate(200%);
        border: solid 1px rgba(var(--text-color), 0.1);
        height: 100%;
        position: relative;
        flex: 1;
        border-radius: 30px;
        box-shadow: 0px 5px 20px 10px rgba(0,0,0, 0.1);

        .sidebar-content{
            height: 100%;
            width: 100%;
            overflow: scroll;
            pointer-events: all;
            position: relative;

            @media (max-width: 800px){
                overflow: visible;
            }
        }

        .sidebar-footer{
            position: absolute;
            bottom: 0px;
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
            background: rgba(var(--background-color), 0.4);
            border-top: solid 1px rgba(var(--text-color), 0.1);
            backdrop-filter: blur(40px);

            @media (max-width: 800px){
                position: fixed;
                border-radius: 0px;
            }
        }

        @media (max-width: 800px) {
            height: 100%;
            display: block;
            overflow: visible;
            padding-bottom: 50px;
            background-color: rgb(var(--background-color));
            backdrop-filter: none;
            border-radius: 0px;
            border: 0px;
            overflow: visible;
            box-shadow: none;
        }
    }

    .sidebar-footer-spacer{
        height: 80px;
        width: 100%;
        display: block;
    }

    .footer-container{
        position: absolute;
        bottom: 0px;
        right: 0px;
        padding: 40px;
    }

    .footer-card{
        background: rgba(var(--background-color), 0.8);
        backdrop-filter: blur(40px) saturate(200%);
        border: solid 1px rgba(var(--text-color), 0.1);
        height: 100%;
        position: relative;
        flex: 1;
        border-radius: 30px;
        box-shadow: 0px 5px 20px 10px rgba(0,0,0, 0.1);
    }
</style>